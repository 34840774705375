require('./bootstrap');

// Make 'VinylShop' accessible inside the HTML pages
import php_project from "./php_project";
window.php_project = php_project;
// Run the hello() function
php_project.hello();

// Niet verwijderen, nodig voor checkbox tot ik iet beter vind.

// const checkbox = document.getElementsByClassName('form-check-input')
// $('#checkbox-value').text($(checkbox).val());
//
// $(checkbox).on('change', function() {
//     if ($(this).is(':checked')) {
//         $(this).attr('value', '1');
//     } else {
//         $(this).attr('value', '0');
//     }
//     $('#checkbox-value').text($(checkbox).val());
// });

